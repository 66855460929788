<template>
	<el-form class="page" :model="mesta" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.naziv')" prop="naziv">
					<el-input v-model="mesta.naziv" :maxLength="100"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.drzava')" prop="id_drzave">
					<select-drzave class="full" v-model="mesta.id_drzave" ref="drzave"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.nazivIzvorni')" prop="naziv_izvorno">
					<el-input v-model="mesta.naziv_izvorno"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.vremePolaska')" class="width-full">
					<el-time-select
							v-model="mesta.vreme_polaska"
							:picker-options="pickerOptions"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.stanicaPolaska')">
					<el-input v-model="mesta.stanica_polaska"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.vremeDolaska')" class="width-full">
					<el-time-select
							v-model="mesta.vreme_dolaska"
							:picker-options="pickerOptions"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.stanicaDolaska')">
					<el-input v-model="mesta.stanica_dolaska"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'mesta-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			mesta: {
				naziv: '',
				id_drzave: null,
				naziv_izvorno: '',
				vreme_polaska: '09:00',
				stanica_polaska: '',
				vreme_dolaska: '21:00',
				stanica_dolaska: ''
			},
			rules: {
				naziv: [{ required: true, max: 100, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_drzave: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				naziv_izvorno: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			pickerOptions: {
				start: '00:00',
				step: '00:01',
				end: '23:59'
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('mesta.mesta')
		});

		this.$utils('stopLoadingAfter', [this.getMesta()]);
	},
	methods: {
		getMesta() {
			if (!this.formCreate) {
				return this.$get('mesta', { id: this.id }).then(data => {
					this.mesta = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.mesta);

				if (this.formCreate)
					this.$save('mesta', data);
				else
					this.$update('mesta', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectDrzave: require('../../components/select/drzave').default
	}
};
</script>
